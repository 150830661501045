import moment from 'moment'
import React from 'react'
import { isCauUser, isManager, isSacUser, isSupervisor, isTechnician } from '../../../helpers/roles'
import CustomMaterialTable from '../../shared/customMaterialTable'
import { displayWithTooltip } from '../../shared/enshortenLargeData'
import ModalConfirm from '../../shared/modalconfirm'
import EditPickUp from './edit'
import ModalAssingBattery from './modalAssing'

class PickUpRequestView extends React.Component {
  componentDidMount() {
    this.props.getBatteryRequests()
    isManager() && this.props.getToBatteriesDeliveryForAdmin()
  }

  constructor(props) {
    super()
    this.state = {
      modalAssign: false,
      modalConfirm: false,
      modalEdit: false,
      itemEdit: {},
    }
    this.openModalAssing = this.openModalAssing.bind(this)
    this.openModalConfirm = this.openModalConfirm.bind(this)
    this.closeEditModal = this.closeEditModal.bind(this)
    this.openModalEdit = this.openModalEdit.bind(this)
    this.closeModal = this.closeModal.bind(this)
  }

  closeModal() {
    this.setState((prev) => {
      return {
        ...prev,
        modalAssign: false,
        modalConfirm: false,
      }
    })
  }

  closeEditModal() {
    this.setState({
      modalEdit: false,
      modalAssign: false,
      modalConfirm: false,
      itemEdit: {},
    })
  }

  openModalAssing(item) {
    this.setState({
      modalAssign: true,
      item: item,
    })
    this.props.getDeliverableForLogged(item.id)
  }

  openModalConfirm(item) {
    this.setState({
      modalConfirm: true,
      item: item,
    })
  }

  openModalEdit(item) {
    this.setState((prev) => {
      return {
        ...prev,
        itemEdit: item,
        modalEdit: true,
      }
    })
    this.props.getById(item.stationId)
    this.props.getByZone(item.zoneId)
  }

  render() {
    const { t, batteriesReducer } = this.props
    return (
      <React.Fragment>
        <CustomMaterialTable
          title={t('batteries.pickUpRequest.title')}
          isLoading={batteriesReducer.loadingResults}
          data={batteriesReducer.batteryRequest}
          exportButton={!isSupervisor() && !isTechnician() && !isCauUser() && !isSacUser()}
          columns={[
            {
              title: t('batteries.pickUpRequest.columns.created'),
              tooltip: t('batteries.pickUpRequest.tooltips.created'),
              field: 'requested',
              render: (rowData) => {
                return rowData.requested
                  ? moment(rowData.requested).format('DD/MM/YYYY HH:mm')
                  : ''
              },
            },
            {
              title: t('batteries.pickUpRequest.columns.customerN'),
              field: 'customerFullName',
              render: (rowData) => displayWithTooltip(rowData.customerFullName),
            },
            {
              title: t('batteries.pickUpRequest.columns.operator'),
              tooltip: t('batteries.pickUpRequest.tooltips.operator'),
              field: 'operatorCiaName',
            },
            {
              title: t('batteries.pickUpRequest.columns.station'),
              field: 'stationNameAndAddress',
              render: (rowData) =>
                displayWithTooltip(rowData.stationNameAndAddress),
            },
            {
              title: t('batteries.pickUpRequest.columns.customer'),
              field: 'customerEmail',
            },
            {
              title: t('batteries.pickUpRequest.columns.batteriesNumber'),
              tooltip: t('batteries.pickUpRequest.tooltips.batteriesNumber'),
              field: 'batteriesNumber',
            },
            /* {
                            title: t('batteries.pickUpRequest.columns.confirmed'),
                            field: 'confirmed',
                            render: rowData => {
                                return rowData.confirmed ? t('batteries.pickUpRequest.columns.yes') : t('batteries.pickUpRequest.columns.no')
                            }
                        } */
          ]}
          actions={[
            {
              icon: 'assignment',
              tooltip: t('batteries.pickUpRequest.actions.assign'),
              onClick: (event, rowData) => this.openModalAssing(rowData),
              hidden: isCauUser(),
            },
            {
              icon: 'edit',
              tooltip: t('batteries.pickUpRequest.actions.edit'),
              onClick: (event, rowData) => this.openModalEdit(rowData),
              hidden: !isManager() && !isSacUser() && !isCauUser() && !isTechnician(),
            },
            /* {
                            icon: "check",
                            tooltip: t("batteries.pickUpRequest.actions.confirm"),
                            onClick: (event, rowData) =>
                                this.openModalConfirm(rowData)
                        } */
          ]}
        />
        <ModalAssingBattery
          open={this.state.modalAssign}
          item={this.state.item}
          onClose={this.closeModal}
          {...this.props}
        />
        <EditPickUp
          onClose={this.closeEditModal}
          open={this.state.modalEdit}
          item={this.state.itemEdit}
          {...this.props}
        />
        <ModalConfirm
          isOpen={this.state.modalConfirm}
          onClose={this.closeModal}
          onConfirm={() => this.props.confirm(this.state.item.id)}
          title={
            t('batteries.pickUpRequest.actions.modal.title') +
            `(${this.state.item && this.state.item.batteryPackIdsString})`
          }
          body={t('batteries.pickUpRequest.actions.modal.body')}
        />
      </React.Fragment>
    )
  }
}

export default PickUpRequestView
