import { Typography } from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip'
import moment from 'moment'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'
import { isCauUser, isManager, isSacUser } from '../../helpers/roles'
import { useDialog } from '../../hooks'
import { alertActions } from '../../redux/actions/alert_actions'
import { ratesActions } from '../../redux/actions/rates_actions'
import { subscriptionsActions } from '../../redux/actions/subscriptions_actions'
import { zonesActions } from '../../redux/actions/zones_actions'
import { subscriptionsService } from '../../services/subscriptionsServices'
import { CancelSubrogateIcon as CancelSubrogationIcon } from '../icons/cancelSubrogate'
import CustomMaterialTable from '../shared/customMaterialTable'
import DatePickerCustom from '../shared/datePickerCustom'
import { displayWithTooltip } from '../shared/enshortenLargeData'
import { formatDateToDisplay } from '../shared/formatDate'
import ConfirmationModal from '../shared/modals/ConfirmationModal'
import Cancel from './dialogs/cancel'
import Create from './dialogs/create'
import ModifyVin from './dialogs/modify-vin'
import Subrogate from './dialogs/subrogate'

const SubscriptionView = ({
  getAll,
  subscriptionsReducer,
  getById,
  cancel,
  cancelAbort,
  getAllRate,
  ratesReducer,
  getAllZones,
  zonesReducer,
  getContract,
}) => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const [selectedSubscriptionId, setSelectedSubscriptionId] = useState(null)
  const [selectedSubscriptionVin, setSelectedSubscriptionVin] = useState(null)
  const [isSelectedSubscriptionActive, setIsSelectedSubscriptionActive] =
    useState(false)
  const [selectedSubscriptionZoneId, setSelectedSubscriptionZoneId] =
    useState(null)

  const cancelDialog = useDialog()
  const cancelConfirmationDialog = useDialog()
  const modifyDialog = useDialog()
  const cancelAbortConfirmationDialog = useDialog()
  const subrogateDialog = useDialog()
  const cancelSubrogationDialog = useDialog()

  const [displayList, setDisplayList] = useState([])
  const [isDialogCreateOpen, setIsDialogCreateOpen] = useState(false)

  const refreshData = useCallback(() => {
    getAll()
    getAllRate()
    getAllZones()
  }, [getAll, getAllRate, getAllZones])

  useEffect(() => {
    refreshData()
  }, [refreshData])

  useEffect(() => {
    let updatedList = subscriptionsReducer.results.map((i) => {
      return {
        ...i,
        rateData: ratesReducer.results.find((n) => n.id === i.rateId),
        zoneData: zonesReducer.results.find((n) => i.zoneId === n.id),
      }
    })
    setDisplayList(updatedList)
  }, [subscriptionsReducer.results, ratesReducer.results, zonesReducer.results])

  const handleOpenContract = (id) => getContract(id)

  function handleCancel(subscription) {
    setSelectedSubscriptionId(subscription.id)
    setIsSelectedSubscriptionActive(subscription.isActive)
    setSelectedSubscriptionZoneId(subscription.zoneId)
    cancelDialog.open()
  }

  function handleCancelAbort(subscriptionId) {
    setSelectedSubscriptionId(subscriptionId)
    cancelAbortConfirmationDialog.open()
  }

  function handleCancelAbortConfirm() {
    cancelAbortConfirmationDialog.close()
    subscriptionsService
      .cancelAbort(selectedSubscriptionId)
      .then(() => {
        dispatch(
          alertActions.success(t('subscriptions.alert.cancelAbort.success')),
        )

        getAll()
        getAllRate()
        getAllZones()
      })
      .catch(() => {
        dispatch(alertActions.error(t('subscriptions.alert.cancelAbort.error')))
      })
  }

  function handleModify(subscriptionId, vin) {
    setSelectedSubscriptionId(subscriptionId)
    setSelectedSubscriptionVin(vin)
    modifyDialog.open()
  }

  function handleSubrogate(subscriptionId) {
    setSelectedSubscriptionId(subscriptionId)
    subrogateDialog.open()
  }

  function handleCancelSubrogation(subscriptionId) {
    setSelectedSubscriptionId(subscriptionId)
    cancelSubrogationDialog.open()
  }

  function handleCancelSubrogationConfirm() {
    cancelSubrogationDialog.close()
    subscriptionsService
      .cancelSubrogation(selectedSubscriptionId)
      .then(() => {
        dispatch(
          alertActions.success(
            t('subscriptions.alert.cancelSubrogation.success'),
          ),
        )

        getAll()
        getAllRate()
        getAllZones()
      })
      .catch(() => {
        dispatch(
          alertActions.error(t('subscriptions.alert.cancelSubrogation.error')),
        )
      })
  }

  return (
    <div>
      <DatePickerCustom searchAction={getAll} />
      <CustomMaterialTable
        title={t('subscriptions.page.table.title')}
        isLoading={subscriptionsReducer.loadingResults}
        columns={[
          {
            title: t('subscriptions.page.table.columns.customerSystemId'),
            field: 'customerSystemId',
            render: (rowData) => displayWithTooltip(rowData.customerSystemId),
          },
          {
            title: t('subscriptions.page.table.columns.name'),
            field: 'rateData.name',
          },
          {
            title: t('subscriptions.page.table.columns.zone'),
            tooltip: t('subscriptions.page.table.tooltips.zone'),
            field: 'zoneData.name',
          },
          {
            title: t('subscriptions.page.table.columns.status'),
            tooltip: t('subscriptions.page.table.tooltips.status'),
            field: 'statusCode',
            render: (rowData) => {
              return (
                <Tooltip title={rowData.statusDescription || ''}>
                  <Typography> {rowData.statusCode || ''} </Typography>
                </Tooltip>
              )
            },
          },
          {
            title: t('subscriptions.page.table.columns.price'),
            tooltip: t('subscriptions.page.table.tooltips.price'),
            field: 'rateData.priceBaseByBatteryFromCustomer',
          },
          {
            title: t('subscriptions.page.table.columns.battery'),
            tooltip: t('subscriptions.page.table.tooltips.battery'),
            field: 'batteriesNumber',
          },
          {
            title: t('subscriptions.page.table.columns.includedKwh'),
            tooltip: t('subscriptions.page.table.tooltips.includedKwh'),
            field: 'rateData.freeMonthlyKwhsDeliveryByBattery',
          },
          {
            title: t('subscriptions.page.table.columns.startDate'),
            field: 'created',
            tooltip: t('subscriptions.page.table.tooltips.startDate'),
            render: (rowData) =>
              rowData?.batteriesDeliveredTimestamp
                ? moment(rowData.batteriesDeliveredTimestamp)
                    .local()
                    .format('DD/MM/YYYY HH:mm')
                : '',
          },
          {
            title: t('subscriptions.page.table.columns.finishDate'),
            field: 'until',
            tooltip: t('subscriptions.page.table.tooltips.finishDate'),
            render: (rowData) => formatDateToDisplay(rowData.until),
          },
        ]}
        data={displayList}
        actions={
          !isCauUser() && [
            {
              icon: 'add',
              tooltip: t('swaps.usage.table.actions.add'),
              isFreeAction: true,
              onClick: () => setIsDialogCreateOpen(true),
              hidden: !isManager() && !isSacUser(),
            },
            {
              icon: 'download',
              tooltip: t('subscriptions.page.table.tooltips.download'),
              hidden: !isManager() && !isSacUser(),
              onClick: (_, rowData) => handleOpenContract(rowData.id),
            },
            (rowData) => ({
              icon: 'transfer_within_a_station_icon',
              tooltip: t('subscriptions.page.table.tooltips.subrogate'),
              hidden:
                !rowData.isActive ||
                rowData.subrogationRequestedTimestamp != null ||
                (!isManager() && !isSacUser()),
              onClick: (_, rowData) => handleSubrogate(rowData.id),
            }),
            (rowData) => ({
              icon: CancelSubrogationIcon,
              tooltip: t('subscriptions.page.table.tooltips.cancelSubrogation'),
              hidden:
                rowData.subrogationRequestedTimestamp == null ||
                (!isManager() && !isSacUser()),
              onClick: (_, rowData) => handleCancelSubrogation(rowData.id),
            }),
            (rowData) => ({
              icon: 'edit',
              tooltip: t('subscriptions.page.table.tooltips.modifyVin'),
              hidden: !isManager() || !rowData.isLinkedToVehicle,
              onClick: (_, rowData) =>
                handleModify(rowData.id, rowData.currentVin),
            }),
            (rowData) => ({
              icon: 'delete',
              tooltip: t('subscriptions.page.table.tooltips.cancel'),
              hidden:
                rowData.subrogationRequestedTimestamp != null ||
                (!isManager() && !isSacUser()) ||
                !['C', 'B', 'A'].includes(rowData.statusCode),
              onClick: (_, rowData) => handleCancel(rowData),
            }),
            (row) => ({
              icon: 'delete_forever',
              tooltip: t('subscriptions.page.table.tooltips.cancelAbort'),
              hidden: !isManager() || !row.isInCancelProcess,
              onClick: (_, rowData) => handleCancelAbort(rowData.id),
            }),
          ]
        }
        exportButton={!isCauUser() && !isSacUser()}
      />

      <Create
        isOpen={isDialogCreateOpen}
        onClose={() => setIsDialogCreateOpen(false)}
      />

      <ModifyVin
        isOpen={modifyDialog.isOpen}
        onClose={modifyDialog.close}
        selectedSubscription={selectedSubscriptionId}
        currentVin={selectedSubscriptionVin}
        onModify={refreshData}
      />

      <Subrogate
        isOpen={subrogateDialog.isOpen}
        onClose={subrogateDialog.close}
        selectedSubscription={selectedSubscriptionId}
        onSubrogate={refreshData}
      />

      <Cancel
        isOpen={cancelDialog.isOpen}
        onClose={cancelDialog.close}
        onCancel={refreshData}
        zoneId={selectedSubscriptionZoneId}
        isActive={isSelectedSubscriptionActive}
        selectedSubscription={selectedSubscriptionId}
      />

      <ConfirmationModal
        open={cancelAbortConfirmationDialog.isOpen}
        onCancel={cancelAbortConfirmationDialog.close}
        onConfirm={handleCancelAbortConfirm}
        title={t('subscriptions.dialogs.cancelAbortSubscription.title')}
        message={t('subscriptions.dialogs.cancelAbortSubscription.body')}
      />

      <ConfirmationModal
        open={cancelSubrogationDialog.isOpen}
        onCancel={cancelSubrogationDialog.close}
        onConfirm={handleCancelSubrogationConfirm}
        title={t('subscriptions.dialogs.cancelSubrogate.title')}
        message={t('subscriptions.dialogs.cancelSubrogate.body')}
      />
    </div>
  )
}

function mapState(state) {
  const { subscriptionsReducer, ratesReducer, zonesReducer } = state
  return { subscriptionsReducer, ratesReducer, zonesReducer }
}

const actionCreators = {
  getAll: subscriptionsActions.getAll,
  getById: subscriptionsActions.getById,
  cancel: subscriptionsActions.cancel,
  cancelAbort: subscriptionsActions.cancelAbort,
  getAllRate: ratesActions.getAllRate,
  getAllZones: zonesActions.getAllZones,
  getContract: subscriptionsActions.getContract,
}

export default connect(mapState, actionCreators)(SubscriptionView)
